<template>
    <front-layout>
        <v-container fluid>
            <v-container v-if="bank.length > 0">
                <v-row>
                    <v-col xs="12" sm="12" md="9" lg="9" xl="9" cols="12" class="pa-0">
                        <!-- Banks filter -->
                        <v-form class="bg_violet px-3 mb-4 pt-1">
                            <v-row>
                                <v-col class="d-flex flex-wrap justify-space-between flex-lg-nowrap pt-8">
                                    <v-text-field v-model="complex" :label="$t('banks_filter.residential_complex')"
                                        class="main_color_text input_text mr-0 mr-lg-3 rounded-0 w-310" solo>
                                    </v-text-field>
                                    <v-select :label="$t('banks_filter.deadline')"
                                        class="color-icon-main input_text mr-0 mr-lg-3 rounded-0 max-width45"
                                        id="color-icon-main" append-icon="mdi-chevron-down" solo depressed
                                        v-model="selectedYear" :items="years">
                                    </v-select>
                                    <v-select :label="$t('banks_filter.rooms')"
                                        class="main_color_text input_text rounded-0 max-width45 width-50" solo
                                        append-icon="mdi-chevron-down" v-model="selectedRooms" :items="rooms">
                                    </v-select>
                                    <div class="wrap_inp ml-0 ml-lg-3 max-width45 width-50">
                                        <v-text-field :value="price" @change="formatPrice"
                                            :type="price !== '' ? 'text' : 'number'" :label="$t('banks_filter.value')"
                                            class="main_color_text input_text rounded-0" solo></v-text-field>
                                    </div>
                                    <div class="wrap_inp max-width45 width-50" v-if="$vuetify.breakpoint.width < 1240">
                                        <v-text-field :value="initialPayment" @change="formatInitialPayment"
                                            :type="initialPayment !== '' ? 'text' : 'number'"
                                            :label="$t('banks_filter.initial_contribution')"
                                            class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                        </v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="d-flex mt-n3 flex-wrap justify-space-between flex-lg-nowrap">
                                    <div class="wrap_inp max-width45" v-if="$vuetify.breakpoint.width > 1240">
                                        <v-text-field :value="initialPayment" @change="formatInitialPayment"
                                            :type="initialPayment !== '' ? 'text' : 'number'"
                                            :label="$t('banks_filter.initial_contribution')"
                                            class="main_color_text input_text mr-0 mr-lg-3 rounded-0" solo>
                                        </v-text-field>
                                    </div>
                                    <div class="wrap_inp w-310 mt-3 mt-lg-0">
                                        <v-text-field :value="slider_term" hide-details @input="formatTerm"
                                            type="number" :label="$t('banks_filter.term')"
                                            class="main_color_text align-center slider_term_text_field rounded-0" solo
                                            single-line>
                                            <template v-slot:append>
                                                <v-slider @input="slider_term = sliderValue" style="" :color="white"
                                                    class="slider_term main_color_text main_color w-310"
                                                    v-model="sliderValue" :min="0" :max="30" hide-details>
                                                </v-slider>
                                            </template>
                                        </v-text-field>
                                    </div>

                                    <div v-if="$vuetify.breakpoint.width > 1240">
                                        <v-btn @click="handleClear"
                                            class="text--white transparent mr-1 text-capitalize subtitle-1 filter_clear_btn mr-2"
                                            depressed>{{ $t('headers.form_filter_btn_clear') }}
                                        </v-btn>
                                        <v-btn
                                            class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                            depressed>{{ $t('headers.form_filter_btn_apply') }}
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="$vuetify.breakpoint.width < 1240">
                                <v-col class="d-flex justify-lg-end justify-space-between mt-6 mt-lg-0 align-center">
                                    <v-btn @click="handleClear"
                                        class="text--white transparent mr-1 text-capitalize subtitle-1 filter_clear_btn mr-2"
                                        depressed>{{ $t('headers.form_filter_btn_clear') }}
                                    </v-btn>
                                    <v-btn
                                        class="main_color_text filter_submit_btn text-capitalize subtitle-1 font-weight-bold"
                                        depressed>{{ $t('headers.form_filter_btn_apply') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-card v-for="item in bank" :key="item.id" flat height="65" class="mb-4">
                            <v-col class="d-flex justify-start align-center px-5 pt-2">
                                <v-img v-if="item.mortgages" contain position="left" height="48" max-width="60"
                                    :src="item.logo_url" />
                                <a :href="`bank/${item.slug}`"
                                    class="main_color_text font-weight-bold text-h6 text-decoration-none d-inline-block mx-5">{{
                                    item.name }}</a>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12" class="pt-0 pr-0 pl-0 pl-md-4">
                        <div v-if="$vuetify.breakpoint.width > 900" class="mt-6 mt-md-0">
                            <aside-link-variable-card />
                        </div>

                        <advertising :limit="2" />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </front-layout>
</template>

<script>
import Bank from "@/models/Bank";

export default {
    name: 'banks.index',

    components: {
        FrontLayout: () => import('@/layouts/FrontLayout'),
        Advertising: () => import('@/components/ads/Advertising'),
        AsideLinkVariableCard: () => import('@/components/cards/AsideLinkVariableCard'),
    },

    data() {
        return {
            bank: [],
            slider_term: '',
            indigo: '#3F44C3',
            green: '#42B261',
            yellow: '#D6A700',
            white: '#FFFFFF',
            years: [
                new Date().getFullYear(),
                new Date().getFullYear() + 1,
                new Date().getFullYear() + 2,
                new Date().getFullYear() + 3,
                new Date().getFullYear() + 4,
                new Date().getFullYear() + 5,
            ],
            complex: null,
            selectedRooms: null,
            selectedYear: null,
            rooms: [],
            price: '',
            initialPayment: '',
            sliderValue: 0
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.banks_title'),
            meta: [
                { name: 'description', content: this.$t('meta.banks_description') }
            ]
        }
    },
    mounted() {
        this.setMeta()
        this.getBankData()
    },
    methods: {
        handleClear() {
            this.complex = null
            this.selectedRooms = null
            this.selectedYear = null
            this.slider_term = ''
            this.price = ''
            this.initialPayment = ''
            this.sliderValue = 0
        },
        setMeta() {
            let metaTagsTitle = document.querySelectorAll('meta[name="title"]')
            let metaTagsDescription = document.querySelectorAll('meta[name="description"]')

            if (metaTagsTitle && metaTagsDescription) {
                for (let i = 0; i < metaTagsTitle.length; i++) {
                    metaTagsTitle[i].remove()
                    metaTagsDescription[i].remove()
                }
            }

            let metaTitle = document.createElement('meta');
            metaTitle.name = 'title'
            metaTitle.content = 'Банки Армении с ипотекой на покупку квартиры в новостройках.'

            let metaDescription = document.createElement('meta')
            metaDescription.name = 'description'

            document.head.appendChild(metaTitle)
        },
        async getBankData() {
            this.isLoading = true

            this.bank = await Bank.get()

            this.years.unshift(this.$t('filterForm.turned_in'))
            this.rooms = this.$t('headers.rooms')

            this.isLoading = false
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        formatInitialPayment(value) {
            let convert = value.toString()
            this.initialPayment = convert.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
        },
        formatPrice(value) {
            let convert = value.toString()
            this.price = convert.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
        },
        formatTerm(value) {
            let convert = value.toString()
            this.slider_term = convert.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
            this.sliderValue = this.slider_term
        }
    }
}
</script>

<style scoped lang="scss">
.bg_bg {
    background: var(--bg);
}

.filter_submit_btn {
    background: #ffffff !important;
    border: 2px solid #3F44C4;
    box-sizing: border-box;
    width: 190px !important;
    height: 50px !important;
    border-radius: 0;
}

.slider_term_text_field {
    position: relative;
    height: 48px;
    color: var(--main_color-base) !important;
}

.slider_term {
    color: var(--main_color-base) !important;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 350px;
}

.wrap_inp {
    position: relative;

    .subtitle_input {
        position: absolute;
        color: white;
        top: -25px;
        left: 0;
        font-size: 16px;
    }
}

.w-310 {
    width: 310px;

    @media all and (max-width: 1240px) {
        width: 100%;
        min-width: 100%;
    }
}

.bg_violet {
    position: relative;
    z-index: 10;

    &::before {
        content: '';
        display: inline-block;
        background: #8e91d9 !important;
        position: absolute;
        top: -100px;
        right: 0;
        bottom: 0;
        width: 3000px;
        height: inherit;
        z-index: -2;
    }
}

.text--white {
    color: white;
}

.max-width45 {
    @media all and (max-width: 1240px) {
        max-width: 45%;
    }

    @media all and (max-width: 770px) {
        max-width: 49%;
    }
}

.width-50 {
    width: 50%
}

.z-index100 {
    z-index: 100;
}
</style>
